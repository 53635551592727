.panorama-viewer {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: no-repeat center center;
    background-size: cover;
    &:before {
      width: 3.5rem;
      height: 3.5rem;
      display: block;
      content: '';
      //background: url('./assets/images/ico-interactive-hor.png') no-repeat center center;
      background-size: contain;
      position: absolute;
      left: calc(50% - 1.75em);
      bottom: 1.5em;
      z-index: 1;
      animation-name: wiper;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    @keyframes wiper {
      100% {
        transform: translateX(15px);
      }
      50% {
        transform: translateX(0px);
      }
      0% {
        transform: translateX(15px);
      }
    }
    canvas {
      position: absolute;
      max-height: 100%;
      &:nth-child(1),
      &:nth-child(2) {
        display: none;
      }
    }
  }