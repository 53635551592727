.level-selector {
    width: 4.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    background: #ddd;
    z-index: 3;
    overflow: auto;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: auto;
    }
    li {
        width: 5em;
        padding: 1em calc(1em + 15px) 1em 1em;
        position: relative;
        font-size: 0.9em;
        text-align: center;
        color: var(--bs-primary);
        cursor: pointer;
        overflow: hidden;
        &:nth-child(even) {
            background-color: #EBEBEB;
          }
        &:hover {
            color: #fff;
            background: #555;
        }
        .badge {
            width: 1.5em;
            height: 1.5em;
            margin-top: -0.35em;
            margin-left: -0.25em;
            position: absolute;
            font-size: 0.65em;
            text-align: center;
            line-height: 1.5em;
            padding: 0;
            border-radius: 50%;
            background-color: rgba(0, 195, 88, 0.75) !important;
        }
        &.active {
            color: #fff;
            background: var(--bs-primary);
        }
    }
    @media screen and (max-width: 1024px) {
        width: 3.5em;
        li {
            width: 3.5em;
            padding: 0.5em
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 200px;
        z-index: 1 !important;
    }
}