.arrow-navigation {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10;
    button {
        width: 2em;
        height: 2em;
        left: 55%;
        padding: 0;
        display: inline-block;
        line-height: 100%;
        border-radius: 50%;
        background: var(--bs-primary) !important;
        color: #fff;
        position: absolute;
    }
    &--top {
        bottom: 25vh;
    }
    &--down {
        top: 35vh;
    }
}