.interested-card {
    &--content {
        max-width: 400px;
        width: 100%;
    }
    .bg-secondary {
        position: relative;
    }
    .modal-body {
        padding: 0;
    }
    .modal-content {
        background-color: transparent;
    }
    .btn-close {
        position: absolute;
        right: 0;
        top: 2em;
    }
    form {
        margin-top: 2em;
    }
    .form-group {
        input {
            background: none;
            border: none;
            border-bottom: 1px solid;
            border-radius: 0;
            color: #fff;
            &::placeholder {
                color: rgba(255,255,255,0.5);
            }
        }
    }
    .form-error {
        font-size: 85%;
        color: #FF1C3B;
    }
    .response {
        color: var(--bs--primary);
        margin-top: 1em;
        &.form-error {
            color: #FF1C3B;
        }
    }
    button {
        margin-top: 3em;
    }
    .ico-loader {
        display: none;
        width: 1em;
        height: 1em;
        margin-right: 10px;
        border: 0.15em solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        vertical-align: middle;
        margin-top: -0.3em;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
    .btn-primary {
        animation: 0.3s all linear;
        &.is-loading {
            .ico-loader {
                display: inline-block;
            }
        }
    }
}