.building-floor-table {
    overflow: auto;
    th {
        padding-bottom: 2em;
        font-size: 0.9rem;
        font-weight: 300;
        border-bottom-color: #000 ;
        .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 1em;
        }
    }
    tr {
        cursor: pointer;
    }
}
