.area {
    &--area {
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 100%;
        margin-bottom: 0.5em;
    }
    &--description {
        li {
            display: flex;
            gap: 10px;
            align-items: center;
            b {
                font-weight: 600;
            }
        }
    }
    &--status {
        line-height: 100%;
        margin-bottom: 0.5em;
    }
    &--price {
        font-weight: 600;
    }
    .status {
        margin-top: 0.1em;
        margin-left: 0.5em;
    }
}
.area-card {
    .modal-header {
        width: 100%;
        border-bottom: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }
    .modal-content {
        height: 100vh;
    }
    .home--logo {
        height: 4rem;
    }
    .btn-back {
        font-size: 2em;
        background: none !important;
        border: none !important;
        color: var(--bs-primary);
        font-weight: 600;
    }
    &--aside {
        width: 300px;
        position: absolute;
        left: 4%;
        top: 7em;
        padding: 20px 15px;
        border-radius: 16px;
        background: rgba(255,255,255,0.75);
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        z-index: 1;
    }
    &--main {
        height: calc(100% - 7em);
        position: relative;
        .area-card--content {
            padding-top: 7em;
            padding-left: 400px;
        }
        &.tab-floor {
            padding-bottom: 10vh;
            &:before,
            &:after {
                width: 20em;
                height: 10em;
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                background:  no-repeat center center;
            }
            &:before {
                left: 4%;
                background-image: url('./../../../public/projects/atiko/deco/img-bike.svg');
            }
            &:after {
                right: 4%;
                background-image: url('./../../../public/projects/atiko/deco/img-sofa.svg');
            }
        }
        &.show-finance {
            background: rgba(194,245,238,1) !important;
        }
    }
    &--main-image {
        text-align: center;
        margin-bottom: 10vh;
        img {
            max-height: 70vh;
        }
    }
    &--gallery {
        > div > div > button {
            border-radius: 50%;
            padding: 0.5em !important;
            background: var(--bs-primary) !important;
        }
        > div > div > button:nth-child(2) {
            left: 420px !important;
        }
    }
    &--tabs-nav {
        a {
            border: none;
            &.active {
                border-radius: 0;
                border-bottom: 1px solid
            }
        }
    }
    &--notations {
        color: #888;
        margin-top: 2em;
        display: flex;
        justify-content: center;
        font-size: 90%;
    }
}
.notation {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em;
    &--voidExt { 
        background-color: #00bcd9; 
    }
    &--voidInt { 
        background-color: #ff8574; 
    }
    &--hall { 
        background-color: #ff2b15; 
    }
    &--apartment { 
        background-color: #9a9a9a; 
    }
    &--window { 
        background-color: #ff8574; 
    }
    &--wall { 
        background-color: #452915; 
    }
}
.status {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    &--0 { // disponible
        background-color: #ababab;
    }
    &--1 { // disponible
        background-color: #00fe5e;
    }
    &--2 { // bloqueado
        background-color: #FF8500;
    }
    &--3 { // bloqueado
        background-color: #FF8500;
    }
    &--4 { // vendido
        background-color: red;
    }
}