header {
    //width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    overflow: hidden;
    transition: .5s all ease-in-out;
    .home--logo {
        margin: 1em auto;
        margin-left: 30px;
        max-height: 4em;
    }
    @media screen and (max-width: 767px) {
        .home--logo {
            width: 180px;
            margin-left: 30px;
            margin-top: 45px;
        }        
    }
}
.home--active {
    header {
        height: 0;
    }
}