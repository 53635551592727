.area {
    &--area {
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 100%;
        margin-bottom: 0.5em;
    }
    &--description {
        li {
            display: flex;
            gap: 10px;
            align-items: center;
            b {
                font-weight: 600;
            }
        }
    }
    &--status {
        line-height: 100%;
        margin-bottom: 0.5em;
        @media screen and (max-width: 767px) {
            text-align: right;
        }
    }
    &--price {
        font-weight: 600;
    }
    .status {
        margin-top: 0.1em;
        margin-left: 0.5em;
    }
}
.area-card {
    .modal-header {
        width: 100%;
        border-bottom: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }
    .modal-content {
        height: 100vh;
    }
    .home--logo {
        height: 4rem;
    }
    .btn-close {
        color: var(--bs-primary);
    }
    .btn-back {
        font-size: 2em;
        background: none !important;
        border: none !important;
        color: var(--bs-primary);
        font-weight: 600;
        &:before {
            width: 100%;
            height: 100%;
            content: '';
            display: block;
            background: red;
        }
        @media screen and (max-width: 767px) {
            font-size: 1.5rem !important;
            border: 1px solid red;
        }
    }
    &--aside {
        width: 300px;
        position: absolute;
        left: 4%;
        top: 7em;
        padding: 20px 15px;
        border-radius: 16px;
        background: rgba(255,255,255,0.75);
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        z-index: 1;
        @media screen and (max-width: 767px) {
            width: 90% !important;
            margin: 30px auto;
            left: auto !important;
            top: auto !important;
            position: relative !important;
        }
    }
    &--main {
        height: calc(100% - 7em);
        position: relative;
        .area-card--content {
            padding-top: 7em;
            padding-left: 400px;
        }
        @media screen and (max-width: 767px) {
            height: auto !important;
            padding-bottom: 0 !important;
            .area-card--content {
                padding-left: 0 !important;
            }
        }
        &.tab-floor {
            padding-bottom: 10vh;
            &:before,
            &:after {
                width: 20em;
                height: 10em;
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                background:  no-repeat center center;
            }
            &:before {
                left: 4%;
                background-image: url('./../../../public/projects/atiko/deco/img-bike.svg');
            }
            &:after {
                right: 4%;
                background-image: url('./../../../public/projects/atiko/deco/img-sofa.svg');
            }
        }
        &.show-finance {
            background: rgba(194,245,238,1) !important;
        }
    }
    &--main-image {
        position: relative;
        text-align: center;
        margin-bottom: 10vh;
        z-index: 1;
        img {
            max-height: 50vh;
        }
        @media screen and (max-width: 767px) {
            padding-left: 15px;            
            padding-right: 15px;      
            margin-bottom: 40px !important;      
        }
    }
    &--gallery {
        > div > div > button {
            border-radius: 50%;
            padding: 0.5em !important;
            background: var(--bs-primary) !important;
        }
        > div > div > button:nth-child(2) {
            left: 420px !important;
        }
        > div > div > div:last-child {
            bottom: 6em !important
        }
    }
    &--tabs-nav {
        a {
            border: none;
            &.active {
                border-radius: 0;
                border-bottom: 1px solid
            }
        }
    }
    &--notations {
        color: #888;
        margin-top: 2em;
        display: flex;
        justify-content: center;
        font-size: 90%;
    }
    &--iframe {
        width: 100%;
        height: 100vh;
        position: relative;
        iframe {
            width: calc(100% - (300px + 8%));
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    &--view-full-bg {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        background:  no-repeat center center;
        background-size: cover;
    }
    .disclaimer {
        font-size: 0.8rem;
        text-align: center;
        margin-bottom: 0;
        color: #888;
        border-radius: 8px;
    }
}
.notation {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em;
    &--voidExt { 
        background-color: #00bcd9; 
    }
    &--voidInt { 
        background-color: #ff8574; 
    }
    &--hall { 
        background-color: #ff2b15; 
    }
    &--apartment { 
        background-color: #9a9a9a; 
    }
    &--window { 
        background-color: #ff8574; 
    }
    &--wall { 
        background-color: #452915; 
    }
}

@media screen and (max-height: 1024px) {
    .active-tab-floor {
        .area-card--main {
            &:before,
            &:after {
                display: none !important;
            }
        }
    }
}