.table-finance {
    background: #fff;
    > :not(caption) > * > * {
        padding: 1em 2em;
        font-weight: 600;
    }
    thead th {
        border: 2px solid var(--bs-primary);
    }
    tbody td {
        color: #444;
        border: 2px solid #000;
    }
}