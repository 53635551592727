.masterplan {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    background: #000;
    display: flex;
    &--content {
        width: 100%;
        position: relative;
    }
}