.home {
    &--wp {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        
        display: flex;
        height: 100%;
        background: no-repeat center center;
        background-size: cover;
    }
    &--content-wp {
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 767px){
            padding-bottom: 7em;
        }
    }
    &--ribbon {
        position: absolute;
        right: -5%;
        top: 10%;
        padding: 0.5em 3em;
        background: red;
        color: #fff;
        transform: rotate(45deg);
        transition: 0.75s all ease-in-out;
        @media screen and (max-width: 767px){
            font-size: 75%;
            top: 5%;
            right: -20vw;            
        }
    }
    &--content {
        width: 90%;
        opacity: 1;
        max-width: 500px;
        text-align: center;
        position: relative;
        transition: .5s all linear;
    }
    &--logo {
        width: 200px;
        height: 100px;
        margin-bottom: 0;
        display: inline-block;
        background: no-repeat center center;
        background-size: contain;
    }
    &--welcome {
        margin: 2em 0;
        font-size: 1em;
        color: #B4ABA1;
    }
    &--closing {
        .home--content-wp {
            display: flex;
            .home--background {
                height: 0%;
            }
            .home--content {
                opacity: 0;
            }
        }
        .home--ribbon {
            opacity: 0;
        }
    }
    &--active {
        .home--wp {
            z-index: 100000 !important;
        }
        .home--content-wp {
            display: flex;
            .home--background {
            height: 100%;
            }
        }
    }
}
@media screen and (max-width: 769px){
    .home {
        &--content {
            width: 100%;
        }
    }    
}