.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10000;
    color: #fff;
    padding: 0 0;
    font-size: 85%;
    > div {
        position: relative;
        z-index: 1;
    }
    p {
        line-height: 100%;
        text-align: center;
        margin: 0;
        margin-bottom: 0.5rem;
        .icon {
            margin-right: .5em;
        }
        small {
            font-size: 85%;
        }
    }
    button, a {
        color: inherit;
    }
    .lg-investments,
    .lg-arq,
    .lg-renderinc,
    .lg-about {
        display: inline-block;
        background: no-repeat center center;
        background-size: contain;
    }
    .lg-investments,
    .lg-arq {
        width: 4vw;
        height: 2.5vw;
    }
    .lg-renderinc,
    .lg-about {
        width: 7vw;
        height: 2vw;
    }
    .lg-investments {
        background-image: url('../../assets/images/img-logo-pop.svg');
    }
    .lg-arq {
        background-image: url('../../assets/images/img-logo-ia-arq.svg');
    }
    .lg-renderinc {
        background-image: url('../../assets/images/img-logo-renderinc.svg');
    }
    .lg-about {
        background-image: url('../../assets/images/img-logo-ia-aboutdesing.svg');
    }
    &--legals {
        font-size: 0.75rem;
        .btn {
            font-size: 0.75rem;
            padding: 0;
        }
    }
    &--background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        content: '';
        background: rgba(0,0,0,0.75);
        transition: 0.5s all linear;
    }
}
.home--active {
    .footer {
        &--background {
            height: 100vh;
            position: fixed;
        }
    }
}

@media screen and (max-width: 768px){
    .footer {
        position: relative;
        padding: 10px;
        font-size: 0.8rem;
        background-color: #272727;
        > .container-fluid > .row > div:nth-child(2) > p:nth-child(1){
            text-align: left;
        }
        > .container > .row > div {
            &:nth-child(1){
                order: 3;
            }
            &:nth-child(2){
                order: 4;
            }
            &:nth-child(3){
                order: 5;
                text-align: center;
            }
            &:nth-child(4){
                order: 1;
            }
            &:nth-child(5){
                order: 6;
                text-align: center;
            }
            &:nth-child(6){
                order: 7;
            }
        }
        .lg-investments,
        .lg-arq {
            width: 5em;
            height: 2.5em;
        }
        .lg-renderinc,
        .lg-about {
            width: 6.2em;
            height: 1.5em;
        }
        p {
            margin-bottom: 0.25em;
        }
    }
}