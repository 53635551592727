.building-spinner {
    width: 100%;
    height: 100%;
    .bar-loader {
        opacity: 0;
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        --c:no-repeat linear-gradient(#B4C98A 0 0);
        background: var(--c),var(--c),#2E91FF;
        background-size: 60% 100%;
        animation: l16 3s infinite;
    }
    @keyframes l16 {
        0%   {background-position:-150% 0,-150% 0}
        66%  {background-position: 250% 0,-150% 0}
        100% {background-position: 250% 0, 250% 0}
    }
    &--frame,
    &--transition {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.35s all linear;
    }
    &--frame {
        display: none;
        background: no-repeat center center;
        background-size: cover;
    }
    &--transition-loader {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: no-repeat center center;
        background-size: cover;
        opacity: 0;
        z-index: 2;
        transition: .3s all linear;
    }
    &--transition {
        z-index: 1;
        transition: .3s all linear;
        .view--transition {
            transition: .3s all linear;
            opacity: 1;
        }
    }
    &--controls {
        width: 100%;
        height: 0;
        position: absolute;
        top: 50%;
        left: 0;
        padding: 0 0.5em;
        z-index: 1;
        transition: 0.35s all linear;
        button {
            width: 3em;
            height: 3em;
            position: relative;
            padding: 0;
            line-height: 100%;
            border-radius: 50%;
            color: #fff;
            transition: .3s all ease-in-out;
            &:first-child {
                float: left;
                margin-left: 2em;
            }
            &:last-child {
                float: right;
                margin-right: 2em;
            }
            .loader {
                opacity: 0;
                width: 24px;
                height: 24px;
                display: block;
                border-radius: 50%;
                position: absolute;
                left: calc(50% - 12px);
                top: calc(50% - 12px);
                animation: rotate 1s linear infinite;
                &:before, 
                &:after {
                    content: "";
                    box-sizing: border-box;
                    position: absolute;
                    inset: 0px;
                    border-radius: 50%;
                    border: 5px solid #FFF;
                    animation: prixClipFix 2s linear infinite ;
                }
                &:after{
                    transform: rotate3d(90, 90, 0, 180deg );
                    border-color: #B4C98A
                }
            }    
            @keyframes rotate {
                0%   {transform: rotate(0deg)}
                100%   {transform: rotate(360deg)}
            }    
            @keyframes prixClipFix {
                0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
                50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
                75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
            }
        }
    }
    &--overhead-button {
        width: 16em;
        position: absolute;
        left: calc(50% - 8em);
        bottom: 15%;
        font-weight: 600;
        color: #fff !important;
        z-index: 2;
        transition: 0.35s all ease-in-out;
    }
    &.is-loading {
        .bar-loader {
            opacity: 1;
        }
        /*
        &.dir-left .building-spinner--controls button:nth-child(1) {
            .icon {
                opacity: 0;
            }
            .loader {
                opacity: 1;
            }
        }
        &.dir-right .building-spinner--controls button:nth-child(2) {
            .icon {
                opacity: 0;
            }
            .loader {
                opacity: 1;
            }
        }*/
        .building-spinner--transition {
            //filter: blur(4px);
        }
        .building-spinner--transition-loader {
            opacity: 0;
        }
        &.is-imageReady {
            .building-spinner--transition-loader {
                opacity: 1;
                //filter: blur(4px);
            }
        }
    }
    &.is-spinning {
        .bar-loader {
            opacity: 0;
        }
        .building-spinner--transition {
            filter: blur(0);
        }
        .building-spinner {
            &--transition-loader {
                opacity: 0;
            }
            &--frame {
                opacity: 0;
            }
            &--controls button,
            &--overhead-button {
                opacity: 0;
                display: none;
            }
        }
    }
    &.is-overhead {
        .building-spinner {
            &--frame {
                opacity: 0;
            }
        }
    }
    &.is-longWait {
        .building-spinner {
            &--overhead-button {
                display: block !important;
                opacity: 1 !important;
            }
        }
    }
    @media screen and (max-width: 767px) {
        &--controls {
            padding-left: 0;
            padding-right: 0;
            button {
                width: 2.5em;
                height: 2.5em;
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
.home--active {
    .building-spinner {
        &--controls,
        &--overhead-button {
            opacity: 0;
        }
    }
}