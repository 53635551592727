.building-floor {
    margin-right: 4.5em;
    position: relative;
    height: 100vh;
    text-align: center;
    background: #fff no-repeat center center;
    background-size: cover;
    overflow: hidden;
    img {
        max-width: 100%;
    }
    &--table {
        display: none !important;
        margin-top: 150px !important;
        margin-bottom: 150px !important;
        position: relative;
        @media screen and (max-width: 767px) {
            display: flex !important;            
        }
        tr {
            td:last-child {
                padding-right: 80px;
            }
        }
        &:after {
            width: 80px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            background: rgb(2,0,36);
            background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%);
        }
    }
    &--home-btn {
        position: absolute;
        left: 2.5%;
        bottom: 4em;
        color: #fff !important;
        z-index: 2;
    }
    &--info {
        width: 20em;
        position: absolute;
        left: 6vw;
        top: 30%;
        color: #fff;
        text-shadow: 0 0 5px rgba(0,0,0,0.25);
        z-index: 2;
        h3 {
            font-size: 2.5rem;
            font-weight: 800;
            line-height: 100%;
        }
        span {
            font-size: 2.5rem;
            font-weight: 600;
            line-height: 100%;
            &.text-alt {
                color: #ff2a00;
            }
        }
    }
    #img-mapper {
        margin-top: -100px;
        area {
            cursor: pointer;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .map-labels {
        position: absolute;
        left: 0;
        top: -100px;
        z-index: 10;
    }
    .map-north {
        width: 130px;
        height: 110px;
        position: absolute;
        right: 10vw;
        bottom: calc(35%);
        z-index: 1000;
        -webkit-mask-image: url('/assets/images/ico-north.svg');
        mask-image: url('/assets/images/ico-north.svg');
        -webkit-mask-repeat: no-repeat !important;
        mask-repeat: no-repeat !important;
        background: #fff;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .map-tooltip {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 0.5em;
        background: rgba(255,255,255,1);
        border-radius: 1rem;
        font-size: 0.75rem;
        transform: translate(-25%,-50%);

        .status-label {
            position: absolute;
            top: 100%;
            left: 24px;
            color: #fff;
            font-weight: 600;
            font-size: 0.85em;
        }

        span {
            width: 0.75rem;
            height: 0.75rem;
            display: block;
            margin-right: 0.5em;
            border-radius: 100%;
        }
        div {
            white-space: nowrap;
        }
    }
    @media screen and (max-height: 768px) {
        margin-left: calc(10% - 72px);
        margin-right: 10%;
        //border: 1px solid green;
        #img-mapper {
            margin-top: 0;
        }
        .map-labels {
            top: 0;
        }
    }
    @media screen and (max-width: 767px) {
        height: auto !important;
        margin-left: 0;
        margin-right: 0;
        .map-labels,
        .arrow-navigation,
        &--info {
            display: none;
        }
    }
}