.ext-modal {
    .modal-dialog {
        --bs-modal-width: 960px;
        .modal-content {
            border: none;
            background: transparent;
            .modal-header {
                border-bottom: none;
            }
            .modal-body {
                .tour-wp {
                    width: 100%;
                    height: 0;
                    padding-bottom: 55%;
                    position: relative;
                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
                .video-wp {
                    width: 100%;
                    height: 0;
                    padding-bottom: 56%;
                    position: relative;
                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
}