// Custom colors
$primary: #2E91FF;
$secondary: #B4CA8A;
$body-color: #4d4d4f;
$secondary-high: #c3552f;
$secondary-med: #f4d5af;
$secondary-light: #f1e9df;
$conexion-light: #d7eed3;

$enable-negative-margins: true;
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@100;200;300;600;800&display=swap');


body {
  font-family: 'Chivo', sans-serif;
}

.btn {
  border-radius: 2em; 
  padding-left: 2em;
  padding-right: 2em;
}
.btn-close {
  width: auto;
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--bs-primary) !important;
  background: none !important;
  opacity: 1;
  span {
    font-size: 125%;
    margin-left: 1rem;
  }
}
.btn-ext-modal {
  display: block;
  margin: 0.5em 0;
}
.ext-modal {
  z-index: 100000;
}
.ext-modals-buttons {
  position: fixed;
  bottom: 5.5em;
  right: 6em;
  z-index: 1000;
  .btn {
    background: #B4C98A;
    border-color: #B4C98A;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 3.5em;
    right: 10px;
    bottom: auto;
    .btn {
      font-size: 0.8em;
    }    
  }
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.gallery-disclaimer {
  position: absolute;
  bottom: -1.5rem;
  right: 1rem;
  color: #fff;
  font-size: 0.75rem;
  z-index: 1;
}
.icon {
  width: 1em;
  height: 2em;
  display: inline-block;
  vertical-align: middle;
  background: no-repeat center center;
  background-size: contain;
  &--bath {
    background-image: url('./assets/images/bano_completo.svg');
  }
  &--rest {
    background-image: url('./assets/images/medio_bano.svg');
  }
  &--parking {
    background-image: url('./assets/images/ico-parking.svg');
  }
  &--room {
    background-image: url('./assets/images/recamara.svg');
  }
  &--kitchen {
    background-image: url('./assets/images/cocina.svg');
  }
  &--dinner {
    background-image: url('./assets/images/comedor.svg');
  }
  &--studio {
    background-image: url('./assets/images/estudio.svg');
  }
  &--living {
    background-image: url('./assets/images/sala.svg');
  }
  &--laundry {
    background-image: url('./assets/images/lavanderia.svg');
  }
  &--terrace {
    background-image: url('./assets/images/terraza.svg');
  }
  &--size {
    background-image: url('./assets/images/superficie.svg');
  }
  &--left {
    background-image: url('./assets/images/ico-left-arrow.png');
  }
  &--location {
    background-image: url('./assets/images/img-marker.svg');
  }
  &--right {
    transform: rotate(180deg);
    background-image: url('./assets/images/ico-left-arrow.png');
  }
  &--top {
    transform: rotate(90deg);
    background-image: url('./assets/images/ico-left-arrow.png');
  }
  &--down {
    transform: rotate(-90deg);
    background-image: url('./assets/images/ico-left-arrow.png');
  }
}
.session-buttons {
  display: block;
  position: absolute;
  left: 5%;
  bottom: 5.5em;
  z-index: 100001;
  .btn {
    display: inline-block;
    color: #fff !important;
    border: 1px solid #fff;
    vertical-align: middle;
    &.forced-index {
      //bottom: 6.75em;
      z-index: 100001;
    }
    &:hover {
      color: var(--bs-primary) !important;
      border: 1px solid var(--bs-primary);
    }
  }
  @media screen and (max-width: 767px) {
    bottom: 12.5em;
  }

}
.status {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  &--undefined { // undefined
      background-color: #ababab;
  }
  &--0 { // no disponible
      background-color: #ababab;
  }
  &--0 { // vendido
    background-color: rgba(255, 42, 0, 1);
  }
  &--1 { // disponible
    background-color: rgba(0, 195, 88, 1);
  }
  &--2 { // separado
    background-color: rgba(255, 204, 28, 1);
  }
}

.layout-container {
  position: relative;
  overflow: hidden;
  > .row {
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  .layout-aside,
  .layout-main,
  .layout-right-aside {
    align-items: center;
    align-content: stretch;
  }
  .layout-aside {
    width: 15%;
    flex: 0 0 auto;
    border-right: 1px solid #eee;
    .panel-aside-title {
      font-size: 0.9em;
      font-weight: 600;
      opacity: 0.75;
      letter-spacing: 0.05em;
    }
    .menu-icon {
      margin-right: 1em;
    }
  }
  .layout-main {
    width: 100%;
    min-height: 100vh;
    background: #F0F4F7;
    @media screen and (max-width: 1024px) {
      padding-bottom: 7.5em;      
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
    .layout-article{
      max-height: calc(100vh - 6em);
      padding-top: 10em;
      padding-left: 10em;
      padding-right: 5%;
      padding-bottom: 5em;
      text-align: justify;
      overflow: auto;
    }
  }
  .layout-rightaside {
    width: 15%;
    flex: 0 0 auto;
    border: 1px solid purple;
  }
}
.home--active .layout-main {
  @media screen and (max-width: 768px) {
    padding-bottom: 0em;      
  }
}

.view {
  position: relative;

  &--transition {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
}

.turn-device-modal {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: var(--bs-primary);
  font-weight: 600;
  text-align: center;
  background: rgba(194,245,238,1);
  z-index: 1000;
}
@media screen and (orientation: portrait) {
  .turn-device-modal {
    //display: flex;
  }
}

.area--price {
  display: none;
}